import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Timeline, Table, Button, DatePicker } from 'antd';
import { GetUserLoginInfos } from '../../api/home';
import './index.less';
function Home() {
    const [yLogins, setYLogins] = useState(0);
    const [tLogins, setTLogins] = useState(0);
    const [mLogins, setMLogins] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [yNewRegUsers, setYNewRegUsers] = useState(0);
    const md = 4;
    useEffect(() => {
        getUserLoginInfos();
    }, []);
    const getUserLoginInfos = async () => {
        try {
            const { code, data } = await GetUserLoginInfos();
            if (code == 200) {
                setYLogins(data.yesterdayLoginSize);
                setTLogins(data.dayLogSize);
                setMLogins(data.monthLogSize);
                setTotalUsers(data.totalUserNumber);
                setYNewRegUsers(data.registeredYesterday);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <div className="gutter-example button-demo home">
            <iframe src="https://share.tcbi.qq.com/page/share?pageId=10541337&projectId=11029501&token=58be5603-2f38-4351-aa6f-6e536629f51f&scope=page&canvasType=GRID"></iframe>
            {/* <Row gutter={16}>
                <Col className="gutter-row" md={md}>
                    <Card>
                        <span>昨日登录数</span>
                        <span>{yLogins}</span>
                    </Card>
                </Col>
                <Col className="gutter-row" md={4}>
                    <Card>
                        <span>今日登录数</span>
                        <span>{tLogins}</span>
                    </Card>
                </Col>
                <Col className="gutter-row" md={4}>
                    <Card>
                        <span>本月登录数</span>
                        <span>{mLogins}</span>
                    </Card>
                </Col>
                <Col className="gutter-row" md={5}>
                    <Card>
                        <span>总用户数</span>
                        <span>{totalUsers}</span>
                    </Card>
                </Col>
                <Col className="gutter-row" md={5}>
                    <Card>
                        <span>昨日新注册用户数</span>
                        <span>{yNewRegUsers}</span>
                    </Card>
                </Col>
            </Row> */}
        </div>
    );
}

export default Home;
